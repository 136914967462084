export function getLanguage() {
  return 'ko_KR';
}

export async function getTranslation(lang = getLanguage()) {
  const translation = await import('./i18n/index').then(({ default: dict }) => dict);

  return {
    [lang]: translation,
  };
}

const getTranslationByKey = (key, language = getLanguage()) => {
  try {
    return window.l10n[language][key] || key;
  } catch (err) {
    return 'Unknown'
  }
};

const formatString = (key, context, language = getLanguage()) => {
  let localizedString = getTranslationByKey(key, language);

  if (!localizedString) {
    return '';
  }

  if (context) {
    Object.keys(context).forEach(contextKey => {
      localizedString = localizedString.replace(`{{${contextKey}}}`, context[contextKey]);
    });
  }

  return localizedString;
};

const t = (key, ...args) => {
  return typeof args[0] === 'object'
    ? formatString(key, ...args)
    : getTranslationByKey(key, ...args);
};

export default t;