import PropTypes from 'prop-types';
import React from 'react';

import './Indicator.scss';

const Indicator = ({ step, length }) => {
  const bullets = [];
  let counter = 0;
  while (counter < length) {
    bullets.push(<span key={counter} className={`step ${((counter === step) ?
      'selected' :
      '')}`} />);
    counter++;
  }

  return (<div className="indicator">{bullets}</div>);
};

Indicator.propTypes = {
  step: PropTypes.number,
  length: PropTypes.number
};

export default Indicator;
