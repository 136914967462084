import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';

import t from 'l10n';

import './SubMenus.scss';

class SubMenus extends Component {
  static propTypes = {
    category: PropTypes.string,
    label: PropTypes.string,
    menus: PropTypes.array,
    selected: PropTypes.string,
    history: PropTypes.object
  };

  menuWidgets() {
    const { category, menus } = this.props;

    return menus
      .filter(menu => !menu.hidden)
      .map((menu, index) => (
        <NavLink to={`/${category}/${menu.route}`} key={index} className="submenu" activeClassName="selected">
          {t(menu.label)} <span className={'marker'} />
        </NavLink>
      ));
  }

  render() {
    const { label } = this.props;
    const menuWidgets = this.menuWidgets();

    return (
      <div className={'submenus'}>
        <div className={'category'}>{t(label)}</div>
        <div className={'menus'}>{menuWidgets}</div>
      </div>
    );
  }
}

export default withRouter(SubMenus);
