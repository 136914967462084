import BorderBoxButton from 'components/BorderBoxButton';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Indicator from './Indicator';
import classNames from 'classnames';

import './MainPage.scss';
import representation from './representation';

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
    };
  }

  shiftPrevStep() {
    this.setState(({ step }) => {
      const next = (step - 1 + representation.length) % representation.length;
      return { step: next }
    });
  }

  shiftNextStep() {
    this.setState(({ step }) => {
      const next = (step + 1) % representation.length;
      return { step: next }
    });
  }

  render() {
    const { step } = this.state;

    return (
      <div className="main-page">
        <div className="presentation">
          <div className="presentation__slogan">
            We Make <span>Games</span> <br /> For Gamers
          </div>
          <div className="presentation__slides">
            {representation.map(this.renderPresentation)}
          </div>
        </div>
        <div className="presentation__move left" onClick={this.shiftPrevStep.bind(this)} />
        <div className="presentation__move right" onClick={this.shiftNextStep.bind(this)} />
        <div className="presentation__indicator">
          <Indicator step={step} length={representation.length} />
        </div>
        {process.env.REACT_APP_VERSION && (
          <div className="webapp-version">{process.env.REACT_APP_VERSION}</div>
        )}
      </div>
    );
  }

  renderPresentation = ({ image, title, desc, links }, index) => {
    const currentStep = this.state.step;

    const linkTo = route => {
      const { history } = this.props;
      history.push(route);
    };

    const navigations = links.map(link => (
      <BorderBoxButton className="link" key={link.label} click={() => linkTo(link.route)}>
        <div className="label">{link.label}</div>
      </BorderBoxButton>
    ));

    const presentationClasses = classNames({
      'presentation__slide': true,
      'presentation__slide--active': index === currentStep,
    });

    return (
      <div className={presentationClasses} key={index}>
        <div className="image">
          <img src={image} alt={title} />
        </div>
        <div className="title">{title}</div>
        <div className="desc">
          {desc.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>
        <div className="links">{navigations}</div>
      </div>
    );
  };
}

export default withRouter(MainPage);
