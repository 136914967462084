import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import './Menu.scss';

const Menu = ({ name, route }) => (
  <div className={'menu'}>
    <NavLink to={`/${route}`} activeClassName="selected">
      {name}
    </NavLink>
  </div>
);

Menu.propTypes = {
  name: PropTypes.string,
  route: PropTypes.string
};

Menu.defaultProps = {
  name: ''
};

export default Menu;
