import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { withRouter } from 'react-router-dom';
import SubMenus from 'components/SubMenus';
import AsideTip from 'components/AsideTip';

import './FeatureInfoPage.scss';
import Loading from './Loading';

import t from 'l10n';

const commonLoading = import('./Loading');

class FeatureInfoPage extends Component {
  static propTypes = {
    configs: PropTypes.object,
    info: PropTypes.object,
    selected: PropTypes.string,
    match: PropTypes.object,
  };

  UNSAFE_componentWillMount() {
    const { history, info, selected } = this.props;
    const { route } = this.props.match.params;
    const { feature } = info;

    !route && history.replace(`${feature}/${selected}`);
  }

  render() {
    const { info, selected } = this.props;
    const { route } = this.props.match.params;
    const { feature, label, menus } = info;

    const selectedMenuRoute = route ? route : selected;
    const currentMenu = menus.filter(menu => menu.route === selectedMenuRoute)[0];
    const featureComponent = currentMenu.component ? currentMenu.component : () => commonLoading;

    // eslint-disable-next-line new-cap
    const LoadableComponent = Loadable({
      loader: () => featureComponent(),
      loading: Loading,
    });

    const { asideTip } = currentMenu;

    return (
      <div className={'feature-page'}>
        <SubMenus category={feature} label={t(label)} menus={menus} selected={selectedMenuRoute} />
        <div className={'contents'}>
          <LoadableComponent configs={this.props.configs} />
          {asideTip && <AsideTip {...asideTip} />}
        </div>
      </div>
    );
  }
}

export default withRouter(FeatureInfoPage);
