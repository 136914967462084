import { AUTH_TOKEN, commonErrorHandler } from 'util/fetch.util';

const accessToken = sessionStorage.getItem(AUTH_TOKEN);
const headers = {};

if (accessToken) {
  headers.Authorization = `Bearer ${accessToken}`;
}

export const query = (configs, uri, payloadHandler) =>
  fetch(`${configs.edgeApi}${uri}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Content-Type': 'Application/json; charset=UTF-8',
    },
    cache: 'no-cache',
    mode: 'cors',
  })
    .then(commonErrorHandler)
    .then(response => (response.ok ? response.json() : Error('Error in getting PI')))
    .then(payload => payloadHandler(payload))
    .catch(() => payloadHandler(null));

export const remove = (configs, uri, handler = () => {}) =>
  fetch(`${configs.edgeApi}${uri}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      'Content-Type': 'Application/json; charset=UTF-8',
    },
    cache: 'no-cache',
    mode: 'cors',
  })
    .then(commonErrorHandler)
    .then(() => handler());

export const put = (configs, uri, payload, handler) =>
  fetch(`${configs.edgeApi}${uri}`, {
    method: 'PUT',
    headers: {
      ...headers,
      'Content-Type': 'Application/json; charset=UTF-8',
    },
    body: JSON.stringify(payload),
    cache: 'no-cache',
    mode: 'cors',
  })
    .then(commonErrorHandler)
    .then(() => handler());

export const post = (configs, uri, payload, handler, noContent = false) =>
  fetch(`${configs.edgeApi}${uri}`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'Application/json; charset=UTF-8',
    },
    body: JSON.stringify(payload),
    cache: 'no-cache',
    mode: 'cors',
  })
    .then(commonErrorHandler)
    .then(response => (noContent ? null : response.json()))
    .then(responseBody => handler(responseBody));

export const form = (configs, uri, payload, handler = () => {}) =>
  fetch(`${configs.edgeApi}${uri}`, {
    method: 'POST',
    headers: {
      ...headers,
    },
    credentials: 'include',
    body: payload,
    cache: 'no-cache',
    mode: 'cors',
  })
    .then(commonErrorHandler)
    .then(() => handler());

export const buildFormData = json => {
  const formData = new FormData();
  Object.keys(json).map(key => {
    const value = json[key];
    return formData.append(key, value.file || value);
  });
  return formData;
};
