const features = [
  {
    feature: 'accounts',
    label: 'menu.accounts.title',
    authRequired: true,
    menus: [
      {
        label: 'menu.accounts.queries',
        route: 'queries',
        component: () => import('pages/feature/accountManagement/AccountManagementPage')
      },
      {
        label: 'menu.accounts.withdraw',
        route: 'withdraw',
        component: () => import('pages/feature/accountManagement/withdrawal/AccountWithdrawalPage')
      },
      {
        label: 'menu.accounts.withdraw.online',
        route: 'online-withdrawal',
        hidden: true,
        component: () => import('pages/feature/accountManagement/withdrawal/OnlineWithdrawalForm')
      },
      {
        label: 'menu.accounts.withdraw.immediate',
        route: 'immediate-withdrawal',
        hidden: true,
        component: () => import('pages/feature/accountManagement/withdrawal/ImmediateWithdrawalPage')
      }
    ],
    default: 'queries'
  },
  {
    feature: 'gameUsage',
    label: 'menu.gameUsage.title',
    authRequired: true,
    menus: [
      {
        label: 'menu.gameUsage.usage',
        route: 'usage',
        asideTip: {
          linkTo: '/gameUsage/privacy',
          linkText: 'gamePlay.usage.online.form.title',
          text: 'gamePlay.usage.aside.text'
        },
        component: () => import('pages/feature/gameUsage/GameUsagePage')
      },
      {
        label: 'menu.gameUsage.shutdown',
        route: 'shutdown',
        component: () => import('pages/feature/gameShutdown/GameShutdownPage')
      },
      {
        label: 'menu.gameUsage.shutdown.schedule',
        route: 'schedule',
        hidden: true,
        asideTip: {
          linkTo: '/faq',
          linkText: 'faq.title',
          text: 'gamePlay.shutdown.aside.text'
        },
        component: () => import('pages/feature/gameShutdown/SelectiveShutdownPage')
      },
      {
        label: 'menu.gameUsage.entitlement',
        route: 'entitlement',
        component: () => import('pages/feature/gameEntitlement/GameEntitlementPage')
      },
      {
        label: 'menu.gameUsage.usage.online',
        route: 'online-usage-request',
        hidden: true,
        component: () => import('pages/feature/gameUsage/GameUsageHistoryRequestForm.js')
      },
      {
        label: 'menu.inquiries.privacy',
        route: 'privacy',
        hidden: true,
        component: () => import('pages/feature/privacy/PrivacyRequestPage')
      }
    ],
    default: 'usage'
  },
  {
    feature: 'payments',
    label: 'menu.payments.title',
    authRequired: true,
    menus: [
      {
        label: 'menu.payments.usage',
        route: 'usage',
        asideTip: {
          linkTo: '/gameUsage/privacy',
          linkText: 'gamePlay.usage.online.form.title',
          text: 'payments.usage.aside.text'
        },
        component: () => import('pages/feature/payments/PaymentsUsagePage')
      }
    ],
    default: 'usage'
  },
  {
    feature: 'guardian',
    label: 'menu.guardian.title',
    authRequired: true,
    menus: [
      {
        label: 'menu.guardian.agreements',
        route: 'agreements',
        component: () => import('pages/feature/guardian/AgreementsOverviewPage')
      },
      {
        label: 'menu.guardian.update',
        route: 'update',
        component: () => import('pages/feature/guardian/GuardianChangeRequestPage')
      },
      {
        label: 'menu.guardian.email',
        route: 'email',
        component: () => import('pages/feature/guardian/GuardianEmailUpdatePage')
      }
    ],
    default: 'agreements'
  },
  {
    feature: 'faq',
    label: 'menu.faq.title',
    authRequired: false,
    menus: [
      {
        label: 'menu.faq.title',
        route: 'articles',
        asideTip: {
          linkTo: '/inquiries/ask',
          linkText: 'inquiries.ask.title',
          text: 'faq.aside.text'
        },
        component: () => import('pages/feature/faq/FaqPage')
      }
    ],
    default: 'articles'
  },
  {
    feature: 'inquiries',
    label: 'menu.inquiries.title',
    authRequired: true,
    menus: [
      {
        label: 'menu.inquiries.ask',
        route: 'ask',
        component: () => import('pages/feature/customerInquiry/TicketRequestForm')
      },
      {
        label: 'menu.inquiries.privacy',
        route: 'privacy',
        hidden: true,
        component: () => import('pages/feature/privacy/PrivacyRequestPage')
      },
      {
        label: 'menu.inquiries.list',
        route: 'list',
        component: () => import('pages/feature/customerInquiry/MyTicketRequests')
      }
    ],
    default: 'list',
  }
];

export default features;
