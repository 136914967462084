import download from 'downloadjs';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import './FileDownloader.scss';

class FileDownloader extends Component {

  _fetchFileFromUrl(url, filename) {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'Application/json; charset=UTF-8',
      },
      credentials: 'include',
      cache: 'no-cache',
      mode: 'cors',
    })
    .then(response => response.blob())
    .then(blob => download(blob, filename));
  }

  render() {
    const url = this.props.url;
    const filename = this.props.filename;
    return (
      <div className='file-downloader' onClick={() => this._fetchFileFromUrl(url, filename)}>
        {this.props.children}
      </div>
    );
  }
}


export const fetchTextFileFromUrl = (url, onComplete) => {
  const reader = new FileReader();
  reader.onload = () => onComplete(reader.result);
  fetch(url)
    .then(response => response.blob())
    .then(blob => reader.readAsText(blob));
};

FileDownloader.propTypes = {
  url: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired
};

export default FileDownloader
