const representation = [
  {
    image: 'https://lolstatic-a.akamaihd.net/riot-kr/images/parent-portal/main_legal.gif',
    title: '법정대리인이란?',
    desc: [
      '법정대리인이란, 일반적으로 친권자 혹은 후견인이 이에 속합니다. 또한 법원의 선임에 의해',
      '부재자의 재산 관리인, 상속 재산 관리인, 유언 집행자 등이 포함되고, 지정 후견인이나 지정 유언 집행자도 해당합니다.',
    ],
    links: [
      { route: '/gameUsage/entitlement', label: '자녀의 계정 정지' },
      { route: '/accounts/withdraw', label: '자녀의 회원 탈퇴' },
    ],
  },
  {
    image: 'https://lolstatic-a.akamaihd.net/riot-kr/images/parent-portal/main_selective.gif',
    title: '게임 시간 선택제',
    desc: [
      '게임 시간 선택제는 청소년 본인 또는 법정대리인께서',
      '게임 이용이 가능한 시간을 직접 선택하여 설정할 수 있는 기능입니다.',
    ],
    links: [
      { route: '/gameUsage/usage', label: '자녀의 이용 시간 열람' },
      { route: '/gameUsage/shutdown', label: '이용 시간 설정' },
    ],
  },
  {
    image: 'https://lolstatic-a.akamaihd.net/riot-kr/images/parent-portal/main_guardian.gif',
    title: '법정대리인 정보 관리',
    desc: [
      '청소년의 회원가입, 유료 결제 서비스, 게임 서비스 이용에 대하여',
      '법정대리인의 동의 현황을 확인하고, 법정대리인 및 이메일 정보를 변경할 수 있습니다.',
    ],
    links: [
      { route: '/guardian/agreements', label: '법정대리인 동의 현황' },
      { route: '/guardian/update', label: '법정대리인 변경' },
    ],
  },
  {
    image: 'https://lolstatic-a.akamaihd.net/riot-kr/images/parent-portal/main_payment.gif',
    title: '결제 정보 관리',
    desc: [
      '만 19세 미만 미성년자 명의 계정에서 결제 서비스를 이용한 내역과',
      '법정대리인 동의 현황에 대해서 확인하실 수 있습니다.',
    ],
    links: [
      { route: '/guardian/agreements', label: '법정대리인 동의 현황' },
      { route: '/guardian/update', label: '법정대리인 변경' },
    ],
  },
];

export default representation;
