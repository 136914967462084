import PropTypes from 'prop-types';
import React from 'react';

import './BorderBoxButton.scss';

const BorderBoxButton = (props) => {
  const additionalClassName = (props.className ? props.className : '');
  return (<div className={`border-box-button ${additionalClassName}`}
    onClick={props.click}>
    <span>{props.children}</span>
  </div>);
};

BorderBoxButton.propTypes = {
  className: PropTypes.string,
  click: PropTypes.func,
  children: PropTypes.node
};

export default BorderBoxButton;
