import React from 'react';
import Redirect from 'react-router/es/Redirect';
import Route from 'react-router/es/Route';

const PrivateRoute = ({ component: Component, isInLogin, authRequired, ...rest }) =>
  <Route {...rest} render={props => (!authRequired || isInLogin) ?
    <Component {...props}/> :
    <Redirect to={{ pathname: '/login' }}/> }/>;

export default PrivateRoute;
