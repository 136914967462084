import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import t from 'l10n';
import Menu from './Menu';
import './MenuArea.scss';

const MenuArea = ({ menus, isInLogin, logout }) => {
  const menuUIs = menus.map(info => <Menu key={info.name} name={info.name} route={info.route} />);
  const loginPlayerName = (
    <span>
      {t('login.login')}
      <div className={'logout'} onClick={logout}>
        {t('login.logout')}
      </div>
    </span>
  );

  return (
    <div className={'menu-area'}>
      <div className={'login'}>
        <Link to={isInLogin() ? '/' : '/login'}>
          {isInLogin() ? loginPlayerName : t('login.not-login')}
        </Link>
      </div>
      <div className="logo">
        <Link to={'/'}>
          <img src={'https://lolstatic-a.akamaihd.net/riot-kr/images/parent-portal/logo_x2.gif'} alt={'logo'} />
        </Link>
      </div>
      <div className="navigation">{menuUIs}</div>
    </div>
  );
};

MenuArea.propTypes = {
  menus: PropTypes.array,
  name: PropTypes.func,
  nav: PropTypes.func
};

export default MenuArea;
