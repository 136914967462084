import classnames from 'classnames';
import t from 'l10n';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import MarkupDisplay from '../MarkupDisplay';
import './asideTip.scss';

const Aside = ({ linkTo, linkText, text, className }) => {
  const cn = classnames({
    'aside-tip': true,
    [className]: !!className
  });

  return (
    <section className={cn}>
      <h2 className="aside-tip-header">{t('aside.tip')}</h2>
      <div className="aside-tip-body"><MarkupDisplay content={t(text)}/></div>

      <Link to={linkTo} className="aside-tip-linkto">
        {t(linkText)}
      </Link>
    </section>
  );
};

Aside.propTypes = {
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Aside;
