import React from 'react';
import sanitizeHtml from 'sanitize-html';

const defaultAllowedTags = ['br', 'p', 'span', 'strong', 'a', 'em', 'u', 'img'];

const defaultAllowedAttributes = {
  '*': ['style'],
  'a': ['href', 'target'],
  'font': ['color'],
  'img': ['src', 'title'],
  'span': ['class'],
};

const MarkupDisplay = ({ content }) => {
  const tokenizedByBR = content.split('<br/>');
  const fullContents = tokenizedByBR.flatMap((line, lineIndex) => {
    const contentStack = [];
    sanitizeHtml(line, { allowedTags: ['strong'] }).split('<strong>').map((strongs, strongIndex) => {
      const indexOfStrongEnd = strongs.indexOf('</strong>');
      if (indexOfStrongEnd >= 0) {
        contentStack.push(<strong key={lineIndex*10 + strongIndex}>{strongs.substring(0, indexOfStrongEnd)}</strong>);
        contentStack.push(strongs.substring(indexOfStrongEnd + 9));
      } else {
        contentStack.push(strongs);
      }
      return strongs;
    });
    contentStack.push(<br key={lineIndex}/>);
    return contentStack;
  });

  fullContents.pop();
  return <span>{fullContents.map(el => el)}</span>;
}

export function parseStringToHtmlAndSanitize(htmlString) {
  return { __html: sanitizeHtml(htmlString, { allowedTags: defaultAllowedTags, allowedAttributes: defaultAllowedAttributes }) };
}

export default MarkupDisplay;
