import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';

import { getTranslation } from 'l10n';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

getTranslation()
  .then(l10n => (window.l10n = l10n))
  .then(l10n => {
    ReactDOM.render(<App />, document.getElementById('root'));
    return l10n;
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
